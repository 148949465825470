/* ibm-plex-sans-cyrillic-ext-400-italic*/
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/ibm-plex-sans-cyrillic-ext-400-italic.woff2') format('woff2'), url('./files/ibm-plex-sans-all-400-italic.woff') format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* ibm-plex-sans-cyrillic-400-italic*/
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/ibm-plex-sans-cyrillic-400-italic.woff2') format('woff2'), url('./files/ibm-plex-sans-all-400-italic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* ibm-plex-sans-greek-400-italic*/
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/ibm-plex-sans-greek-400-italic.woff2') format('woff2'), url('./files/ibm-plex-sans-all-400-italic.woff') format('woff');
  unicode-range: U+0370-03FF;
}
/* ibm-plex-sans-vietnamese-400-italic*/
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/ibm-plex-sans-vietnamese-400-italic.woff2') format('woff2'), url('./files/ibm-plex-sans-all-400-italic.woff') format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* ibm-plex-sans-latin-ext-400-italic*/
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/ibm-plex-sans-latin-ext-400-italic.woff2') format('woff2'), url('./files/ibm-plex-sans-all-400-italic.woff') format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* ibm-plex-sans-latin-400-italic*/
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/ibm-plex-sans-latin-400-italic.woff2') format('woff2'), url('./files/ibm-plex-sans-all-400-italic.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
