// Bootstrap

// Toggle global options
// $enable-gradients: true;
$enable-shadows: false;
$enable-rounded: false;

$border-radius:               0;
$border-radius-pill:          0;
$modal-content-border-radius: 0;

// Customize some defaults, i.e.
// $body-color: #fff;
// $body-bg: #333;

$dark: #000;
$light: #FFF;
$danger: #f00;

$bg-light: #FFF;
$bg-dark: #000;

$navbar-dark: #000;


// Import all (without functions)
@import "../../node_modules/bootstrap/scss/bootstrap";

// Import Bootstrap Icons
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";


// Custom

body {
    margin: 0;
    font-family: "IBM Plex Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial;
  }
  #map {
    width: 100vw;
    height: 100vh; //calc(100vh - 40px); /* -navbar bottom case not transparent */
  }
  #progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    box-shadow: 0px 0px 1px 5px rgb(0,0,0);
    width: 0;
    transition: width 250ms;
  }
  .ol-control button {
    background: rgba(0,0,0,1) !important;
    width: 2.4rem !important;
    height: 2.4rem !important;
    margin: 3px !important;
  }
  #cross {
    position: absolute;
    left: 50vw;
    top: calc(50vh - 0px); // -40px i.e. with navbar shift
    z-index: 10;
    font-size: 24px;
    color: red !important;
  }
  #values {
    position: absolute;
    z-index: 10;
    right: 15px;
    margin-top: 11px;
    margin-left: 60px;
  }


  div.awesomplete {
    display: block;
  }
  .awesomplete > ul { max-height: 50vh; overflow-y: auto; }

  .ol-popup {
    position: absolute;
    background-color: white;
    padding: 15px;
    border-radius: 0;
    border: 0;
    bottom: 12px;
    left: -50px;
    min-width: 280px;
    max-height: 400px;
    overflow: auto;
    display: none;
  }
  .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
  }
  .ol-popup-closer:after {
    content: "✖";
    color: black;
  }